import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  video?: string
}

export const BlockVideo = memo(function BlockVideo({ video }: Props) {
  return (
    <Container>
      <VideoWrapper>
        <FadeInUp>
          <video playsInline autoPlay muted loop src={video}></video>
        </FadeInUp>
      </VideoWrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.6vw;

  video {
    display: block;
  }

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const VideoWrapper = styled.div`
  video {
    width: 100%;
    height: auto;
  }
`
